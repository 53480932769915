import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import GetInTouch from "../components/get-in-touch";

interface Snippet {
	category: string;
	name: string;
	tag?: string;
	slug: string;
}

const IndustriesPage = () => {
	const { t } = useTranslation();

	const INDUSTRIES: { industry: string; snippets: Snippet[] }[] = [
		{
			industry: t("Aviation"),
			snippets: [
				{
					category: t("Product"),
					name: t("industries:DEEP Airline Booking Engine"),
					slug: "/products/airline-booking-engine",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud Application Developement", "Cloud Application Development"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Service"),
					name: t("industries:eCommerce Web Developement"),
					tag: "Development",
					slug: "/services#development",
				},
				{
					category: t("Product"),
					name: t("industries:DEEP Travel Connector"),
					slug: "/products/travel-connector",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud PCI-DSS Compliancy"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Product"),
					name: t("industries:DEEP Business Travel for Airlines"),
					slug: "/products/business-travel-management",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud and Data Architecture"),
					tag: "Consulting",
					slug: "/services#consulting",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud Migration and Optimization"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
			],
		},
		{
			industry: t("Tour Operator", "Tour Operator"),
			snippets: [
				{
					category: t("Product"),
					name: t("industries:DEEP Travel Reservation System"),
					slug: "/products/travel-reservation-system",
				},
				{
					category: t("Product"),
					name: t("industries:DEEP Business Travel Management"),
					slug: "/products/business-travel-management",
				},
				{
					category: t("Service"),
					name: t("industries:Backend System Development"),
					tag: "Development",
					slug: "/services#development",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud Migration and Optimization"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Service"),
					name: t("industries:24/7 Cloud Operations"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud PCI-DSS Compliancy"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Service"),
					name: t("industries:eCommerce Web Development"),
					tag: "Development",
					slug: "/services#development",
				},
				{
					category: t("Service"),
					name: t("industries:Software Integrations"),
					tag: "Development",
					slug: "/services#development",
				},
				{
					category: t("Service"),
					name: t("industries:Airline and Tour Operator IT"),
					tag: "Consulting",
					slug: "/services#consulting",
				},
			],
		},
		{
			industry: t("eCommerce", "eCommerce"),
			snippets: [
				{
					category: t("Service"),
					name: t("industries:Cloud Migration and Optimization"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Service"),
					name: t("industries:eCommerce IT Solutions"),
					tag: "Consultancy",
					slug: "/services#consulting",
				},
				{
					category: t("Service"),
					name: t("industries:Backend System Development"),
					tag: "Development",
					slug: "/services#development",
				},
				{
					category: t("Service"),
					name: t("industries:eCommerce Web Development"),
					tag: "Development",
					slug: "/services#development",
				},
				{
					category: t("Product"),
					name: t("industries:DEEP Connector"),
					slug: "/products/travel-connector",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud PCI-DSS Compliancy"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud Application Development"),
					tag: "Cloud",
					slug: "/services#cloud",
				},
				{
					category: t("Service"),
					name: t("industries:Software Integrations"),
					tag: "Development",
					slug: "/services#development",
				},
				{
					category: t("Service"),
					name: t("industries:Cloud and Data Architecture"),
					tag: "Consultancy",
					slug: "/services#consulting",
				},
			],
		},
	];

	const getInTouchArray: string[] = [
		t("Do you have a project but don’t know where to start?"),
		t(
			"Are you looking for a reliable service provider who will support you during the entire project and even after?"
		),
		t("Already received several offers to your project but not satisfied with the proposals?"),
	];

	const data = useStaticQuery(graphql`
		query IndustriesPageQuery {
			allFile(filter: { relativePath: { in: ["banner/industries.jpg"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

	const fluidBanner = data.allFile.edges[0].node.childImageSharp.fluid;

	const Snippet = ({ snippet }: { snippet: any }) => (
		<div className="p-4 my-2 mr-2 relative shadow-md bg-white sm:h-32 xl:h-24">
			<div className="flex justify-between">
				<div>
					<div className="text-lg">{snippet.category}</div>
					<div className="border border-1 border-brand-green"></div>
				</div>
				<div className="uppercase">{snippet.tag ? snippet.tag : ""}</div>
			</div>
			<div className="text-xl font-semibold sm:mt-2 xl:mt-0 pr-8">{snippet.name}</div>
			<div>
				<div className="text-brand-green hover:text-brand-grayt text-right absolute inset-x-0 bottom-0 mb-4 lg:mb-2 mr-5">
					&#10230;
				</div>
			</div>
		</div>
	);

	return (
		<Layout>
			<SEO title="Industries" />
			<div className="text-brand-grayt">
				{/* Banner */}
				<BackgroundImage Tag="div" fluid={fluidBanner}>
					<div className="container text-white h-400px pt-4">
						<div className="lg:w-2/3 px-4 highlight">
							<h1 className="pt-12 xl:pt-16 text-4xl sm:text-4xl leading-tight font-bold">
								<span>
									<Trans ns="industries">Aviation, Tour Operator and eCommerce</Trans>
								</span>
							</h1>
							<p className="sm:text-lg mt-5">
								<span>
									<Trans i18nKey="DGITAL Labs was established" ns="industries">
										DGITAL Labs was established with the goal of providing professional eCommerce
										services to enterprises and large organizations with the highest security
										standards including software development, 24/7 operations and consulting.
									</Trans>
								</span>
							</p>
							<p className="hidden sm:block text-lg mt-3 pb-8">
								<span>
									<Trans i18nKey="We are working together with airlines" ns="industries">
										We are working together with airlines, tour operators, travel agencies and all
										kinds of eCommnerce companies to help achieve their business goals and make a
										better shopping experience to their customers.
									</Trans>
								</span>
							</p>
						</div>
					</div>
				</BackgroundImage>
				{/* Aviation */}
				<div id="aviation" className="bg-brand-gray4">
					<div className="container p-4">
						<div>
							<div className="mt-4 mb-6">
								<h1 className="inline text-3xl font-bold border-b-2 border-brand-green">Aviation</h1>
							</div>
							<div className="flex flex-col sm:flex-row items-center sm:items-start">
								<div className="w-full s:w-1/2 sm:pr-12 mb-4">
									<p className="text-lg mb-4">
										<Trans
											i18nKey="DGITAL is an enterprise level travel technology company"
											ns="industries"
										>
											DGITAL is an enterprise level travel technology company that has a unique
											set of know-how and experience from the ULLC business industry including
											Microsoft .NET programming combined with cloud solutions and the latest
											frontend technologies.
										</Trans>
									</p>
									<p className="text-lg mb-4">
										<Trans i18nKey="We are delivering custom developement projects" ns="industries">
											We are delivering custom development projects to our airline partners with
											full project management starting from the business analysis until the
											production release, we also do cloud DevOps and 24/7 operations to cover all
											the needs of our partners. We also provide pre-built software products
											specifically designed for ULCC carriers.
										</Trans>
									</p>
									<p className="text-lg">
										<Trans i18nKey="The company was founded by Zsolt S Csernak" ns="industries">
											The company has been founded by Zsolt S. Csernak - Head of Technology of
											Wizz Air between 2003 and 2015 - and employs 20+ Developers and business
											professionals in Budapest, Hungary.
										</Trans>
									</p>
								</div>
								<div className="w-full s:w-1/2 mb-4">
									<h2 className="text-2xl mb-4 font-semibold">
										<Trans>Projects</Trans>
									</h2>
									<ul className="text-lg list-disc list-inside leading-8">
										<li>
											<Trans ns="industries">Booking website re-architect and redesign</Trans>
										</li>
										<li>
											<Trans ns="industries">New Airline and PSS system launch</Trans>
										</li>
										<li>
											<Trans ns="industries">Dynamic packaging system introduction</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Ancillary product development and implementation
											</Trans>
										</li>
										<li>
											<Trans ns="industries" i18nKey="Third party ancillary integration">
												Third party ancillary integration (e.g. insurance, hotel, transfer)
											</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Card and other bank-processed payment method developments
											</Trans>
										</li>
										<li>
											<Trans ns="industries">Loyalty program establishment</Trans>
										</li>
										<li>
											<Trans ns="industries">Bundle management system development</Trans>
										</li>
										<li>
											<Trans ns="industries">CRM/CDP and ERP system integrations</Trans>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<h1 className="text-2xl font-semibold block mt-6 mb-2">
							<Trans ns="industries">Products & Services</Trans>
						</h1>
						<div className="flex flex-col items-center sm:flex-wrap sm:flex-row sm:items-start sm:justify-between">
							{INDUSTRIES[0].snippets.map((snippet) => (
								<Link to={snippet.slug} key={snippet.name} className="w-full sm:w-1/2 lg:w-1/3">
									<Snippet snippet={snippet} />
								</Link>
							))}
						</div>
					</div>
				</div>
				{/* Tour Operator */}
				<div id="tourOperator" className="bg-brand-white">
					<div className="container p-4">
						<div>
							<div className="mt-4 mb-6">
								<h1 className="inline text-3xl font-bold border-b-2 border-brand-green">
									<Trans ns="industries">Tour Operator</Trans>
								</h1>
							</div>
							<div className="flex flex-col sm:flex-row items-center sm:items-start">
								<div className="w-full s:w-1/2 sm:pr-12 mb-4">
									<p className="text-lg mb-4">
										<Trans i18nKey="Since the establisment of DGITAL" ns="industries">
											Since the establishment of DGITAL we have developed a travel service product
											portfolio with the goal of providing high value and excellent online
											solutions for Tour Operators and Travel Agencies.
										</Trans>
									</p>
									<p className="text-lg mb-4">
										<Trans
											i18nKey="The Team has a wide range of experience from travel"
											ns="industries"
										>
											The Team has a wide range of experience from the travel, tour operator and
											OTA industries both on the technical side and the business management. Our
											developers worked for Agoda and major Hungarian tour operators, while on the
											business side Wizz Air, Booking.com and Kuoni Travel made a big influence on
											our team members.
										</Trans>
									</p>
									<p className="text-lg">
										<Trans
											i18nKey="Our flagship software is DEEP Travel Reservation System"
											ns="industries"
										>
											Our flagship software is the DEEP Travel Reservation System that is capable
											for dynamic packaging with the most common components, however we also
											provide custom development solutions, 24/7 website operations and IT
											consulting to our partners.
										</Trans>
									</p>
								</div>
								<div className="w-full s:w-1/2 mb-4">
									<h2 className="text-2xl mb-4 font-semibold">
										<Trans ns="industries">Projects</Trans>
									</h2>
									<ul className="text-lg list-disc list-inside leading-8">
										<li>
											<Trans ns="industries">Dynamic packaging system launch migration</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Tour operator website development and 24/7 operations
											</Trans>
										</li>
										<li>
											<Trans ns="industries">CRM and CMS system integration</Trans>
										</li>
										<li>
											<Trans ns="industries">Email marketing solution development</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Online card and bank transfer payment process solution
											</Trans>
										</li>
										<li>
											<Trans ns="industries">Advanced SEO and analytics development</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Price prediction engine and other backoffice developments
											</Trans>
										</li>
										<li>
											<Trans ns="industries">Third party service integrations</Trans>
										</li>
										<li>
											<Trans ns="industries">Infrastructure optimization and operation</Trans>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<h1 className="text-2xl font-semibold block mt-6 mb-2">
							<Trans ns="industries">Products & Services</Trans>
						</h1>
						<div className="flex flex-col items-center sm:flex-wrap sm:flex-row sm:items-start sm:justify-between">
							{INDUSTRIES[1].snippets.map((snippet) => (
								<Link to={snippet.slug} key={snippet.name} className="w-full sm:w-1/2 lg:w-1/3">
									<Snippet snippet={snippet} />
								</Link>
							))}
						</div>
					</div>
				</div>
				{/* eCommerce */}
				<div id="eCommerce" className="bg-brand-gray4">
					<div className="container p-4">
						<div>
							<div className="mt-4 mb-6">
								<h1 className="inline text-3xl font-bold border-b-2 border-brand-green">
									<Trans>eCommerce</Trans>
								</h1>
							</div>
							<div className="flex flex-col sm:flex-row items-center sm:items-start">
								<div className="w-full s:w-1/2 sm:pr-12 mb-4">
									<p className="text-lg mb-4">
										<Trans i18nKey="The eCommerce business has many challenges" ns="industries">
											The eCommerce business has many challenges including the growing customer
											base and data sets, supply chain management and process automation, demand
											satisfaction and continuous improvement of the services, meanwhile the
											operational costs shouldn’t grow at the same pace.
										</Trans>
									</p>
									<p className="text-lg mb-4">
										<Trans
											i18nKey="With our more than 25-year eCommerce and IT experience"
											ns="industries"
										>
											With our more than 25-year eCommerce and IT experience we built the
											solutions to all of the above mentioned challenges. We do custom web and
											backend system development including business analysis, user experience
											design, cloud architecture, DevOps, quality assurance and 24/7 operations.
											Our team use the latest technologies and provides support during the entire
											project from the planning until the successful production release or more.
										</Trans>
									</p>
									<p className="text-lg">
										<Trans i18nKey="We also provide pre-built software products" ns="industries">
											We also provide pre-built software products that can be easily adopted to
											various eCommerce business models.
										</Trans>
									</p>
								</div>
								<div className="w-full s:w-1/2 mb-4">
									<h2 className="text-2xl mb-4 font-semibold">
										<Trans ns="industries">Projects</Trans>
									</h2>
									<ul className="text-lg list-disc list-inside leading-8">
										<li>
											<Trans ns="industries">
												Website user experience design and development
											</Trans>
										</li>
										<li>
											<Trans ns="industries">Security hardening and secure data storage</Trans>
										</li>
										<li>
											<Trans ns="industries">Backend system optimization and modernization</Trans>
										</li>
										<li>
											<Trans ns="industries">Custom eCommerce engine development</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Accounting, logistics, CRM and ERP system integrations
											</Trans>
										</li>
										<li>
											<Trans ns="industries">PCI-DSS and GDPR compliancy developments</Trans>
										</li>
										<li>
											<Trans ns="industries">Process automation and process optimization</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Cloud migration, optimization and cloud operations
											</Trans>
										</li>
										<li>
											<Trans ns="industries">
												Card payment provider and social login integrations
											</Trans>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<h1 className="text-2xl font-semibold block mt-6 mb-2">
							<Trans ns="industries">Products & Services</Trans>s
						</h1>
						<div className="flex flex-col items-center sm:flex-wrap sm:flex-row sm:items-start sm:justify-between mb-2">
							{INDUSTRIES[2].snippets.map((snippet) => (
								<Link to={snippet.slug} key={snippet.name} className="w-full sm:w-1/2 lg:w-1/3">
									<Snippet snippet={snippet} />
								</Link>
							))}
						</div>
					</div>
				</div>
				<GetInTouch getInTouchArray={getInTouchArray} />
			</div>
		</Layout>
	);
};

export default IndustriesPage;
